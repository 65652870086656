import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from "@angular/core";
import {SelectedChartAction} from '../../backend/analytics.state';

export declare let require: any;
export declare let Highcharts: any;


class Col {
    data: any;
}

class Row {
    cols: Col[] = [];
}

@Component({
    selector: 'multi-hc',
    template: '<div class="row" *ngFor="let row of rows"><div *ngFor="let col of row.cols" class="col-{{_bootstrap_col}}"><adw-hc (action)="onAction(col,$event)" [zoomPosition]="\'tl\'" [options]="col.data"></adw-hc></div> </div>'
})
export class AdwMultiHighchart implements AfterViewInit, OnInit, OnDestroy {
    private _data: any = null;

    rows: Row[] = [];
    numberOfColumns: number = 3;

    @Output()
    action: EventEmitter<SelectedChartAction> = new EventEmitter<SelectedChartAction>();

    get _bootstrap_col() {
        return 12 / this.numberOfColumns;
    }

    @Input()
    set data(o: any) {
        this._data = o;
        let me = this;
        this.rows = [];
        if (o && o.length) {
            this.numberOfColumns = 3;
            if (o.length < 3) this.numberOfColumns = 2;
            o.forEach((value, index, array) => {
                let rowIdx = Math.floor(index / this.numberOfColumns);
                while (me.rows.length <= (rowIdx)) {
                    let row = new Row();
                    row.cols = [];
                    me.rows.push(row);
                }
                let row = me.rows[rowIdx];
                if (row) {
                    row.cols.push({data: value} as Col)
                } else {
                    console.log("********************************")
                }

            });
        }
        this.changeDetection.detectChanges();
    }

    get options(): any {
        return this._data;
    }

    constructor(private changeDetection: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    onAction(col: Col, $event: SelectedChartAction) {
        this.action.emit($event);
    }
}
