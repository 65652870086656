import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface Script {
  src: string;
  loaded?: BehaviorSubject<boolean>;
  status?: string;
}

@Injectable({providedIn: 'root'})
export class ScriptService {
  public _scripts: Map<string, Script> = new Map<string, Script>();

  private enabled: boolean = false;

  async asyncLoad(tag, script: string) {
    return await this.load(tag, script);
  }

  load(tag, ...scripts: string[]): Promise<any> {
    if (!this.enabled) {
      return new Promise<any>(resolve => resolve(true));
    }

    const scriptObjects = scripts.map((src: string) => {
      if (!this._scripts.get(src)) {
        this._scripts.set(src, {src: src});
      }
      return this._scripts.get(src);
    });

    const promises: any[] = [];
    scriptObjects.filter(src => !src.loaded).forEach(src => promises.push(this.loadScript(src)));
    if (promises.length > 0) {
      console.log('**** Script Service: Loading ' + promises.length + ' scripts');
      return Promise.all(promises);
    }
    return new Promise<any>(resolve => resolve(true));
  }

  loadScript(scriptObject: Script) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (scriptObject.loaded) {
        if (scriptObject.loaded.getValue()) {
          resolve(scriptObject);
        } else {
          const subscription = scriptObject.loaded.subscribe((x) => {
            if (x) {
              resolve(scriptObject);
              subscription.unsubscribe();
            }
          });
        }
      } else {
        scriptObject.loaded = new BehaviorSubject<boolean>(false);
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptObject.src;
        script.onload = () => {
          console.log(scriptObject.src + ' loaded');
          resolve(scriptObject);
          scriptObject.loaded.next(true);
        };
        script.onerror = (error: any) => {
          scriptObject.loaded.next(true);
          scriptObject.status = 'Loaded Error:' + error.toString();
          reject(scriptObject);
        };
        document.head.appendChild(script);
      }
    });
  }

  reloadOnSessionChange() {
    window.addEventListener('storage', function(data) {
      if (data['key'] === 'token' && data['oldValue'] == null && data['newValue']) {
        document.location.reload();
      }
    });
  }
}
