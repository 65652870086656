import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfigurationService} from './configuration.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
  providers: []
})
export class ConfigurationModule {
  static forRoot(): ModuleWithProviders<ConfigurationModule> {
    return {ngModule: ConfigurationModule, providers: [ConfigurationService]};
  }
}

