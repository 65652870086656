import {CommonModule} from "@angular/common";
import {Popover} from "./popover";
import {PopoverContent} from "./popovercontent";
import {NgModule} from "@angular/core";

export * from "./popover";
export * from "./popovercontent";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PopoverContent,
        Popover,
    ],
    exports: [
        PopoverContent,
        Popover,
    ]
})
export class PopoverModule {

}