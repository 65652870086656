import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {NGXLogger} from './utils/logging/logger.service';
import {ConfigurationService} from './configuration/configuration.service';
import {AuthenticationService} from './security/auth.service';
import {DOCUMENT} from '@angular/common';

@Injectable({providedIn: 'root'})
export class AppResolver implements Resolve<any> {

  constructor(@Inject(DOCUMENT) private document: Document, private logger: NGXLogger, private router: Router, private auth: AuthenticationService, private config: ConfigurationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.logger.info('AppResolver.resolve()');
    const routeParams = route.params;
    return new Promise((resolve, reject) => {
      const config = this.config.getConfiguration();
      if (!config) {
        this.config.loadConfiguration().then(value => {
          this.doResolve(value);
          resolve({});
        });
      } else {
        this.doResolve(config);
        resolve({});
      }
    });
  }

  doResolve(config) {
    const valid = config ? config.project : null;
    if (!valid) {
      this.router.navigate(['/pages/offline']);
    } else {
      let path = '/analytics';
      if (this.auth.isLoggedIn()) {
        if (config.standardLoggedInPath) {
          path = config.standardLoggedInPath;
        }
      } else {
        if (config.standardPath) {
          path = config.standardPath;
        } else {
          path = '/pages/login';
        }
      }
      this.logger.info('welcome, we guide you to this location', path);
      if (path.startsWith('/')) {
        this.router.navigate([path]);
      } else {
        this.document.location.href = path;
      }
    }
  }
}
