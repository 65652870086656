import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {MatrixTimeseriesData} from './matrix_ts_data';
import {NGXLogger} from '../logging/logger.service';
import {DynData} from './dyn_data';
import {ScriptService} from '@adw/services/script.service';

@Component({selector: 'dyn-matrix', template: '<div></div>'})
export class DynMatrixComponent implements AfterViewInit {

  enableZoomFunction: boolean = false;
  xScale: any;
  overlay: any;
  yScale: any;
  radiusScale: any;
  colorScale: any;
  xAxis: any;
  svg: any;
  yAxis: any;
  _data: MatrixTimeseriesData;
  _bands: any[];
  dot: any;
  line: any;
  text: any;

  @Output()
  zoom: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set bands(b: any[]) {
    this._bands = b;
  }

  @Input()
  @HostBinding('class.notrace')
  notrace: boolean = false;

  @Input()
  get data(): any[] {
    if (this._data) {
      return this._data.data;
    }
    return null;
  }

  drawDate(t, date) {
    const me = this;
    const week = d3.interpolateDate(this._data.startdate, this._data.enddate);
    const interPolWeek = week(t);
    me.displayweek(interPolWeek, date);
  }


  set data(m: any[]) {
    if (m) {
      this._data = new MatrixTimeseriesData(this.logger, m);
    }
  }


  constructor(private elementRef: ElementRef, private logger: NGXLogger, private scriptService: ScriptService) {
  }

  ngAfterViewInit(): void {
  }


  redraw() {
    this.elementRef.nativeElement.innerHTML = '';
    if (this._data) {
      const me = this;
      const margin = {top: 20, right: 20, bottom: 40, left: 60},
        width = this.elementRef.nativeElement.getBoundingClientRect().width - margin.right - margin.left,
        height = this.elementRef.nativeElement.getBoundingClientRect().height - margin.top - margin.bottom;

      me.xScale = d3.scaleLinear().domain([this._data.minX, this._data.maxX]).range([0, width]);
      const xAxisScale = d3.scaleOrdinal().domain(['', 'negative', 'positive', '']).range([0, 25, width - 25, width]);
      me.yScale = d3.scaleLinear().domain([this._data.minY, this._data.maxY]).range([height, 0]);
      me.radiusScale = d3.scaleSqrt().domain([this._data.minR, this._data.maxR]).range([0, 40]);
      me.colorScale = d3.scaleOrdinal(d3.schemeCategory10);

      this.xAxis = d3.axisBottom(xAxisScale).tickSize(0); // .ticks(12, d3.format(",d"));
      const xAxis2 = d3.axisBottom(me.xScale);
      this.yAxis = d3.axisLeft(me.yScale);

      // Create the SVG container and set the origin.
      const svgEle = d3.select(this.elementRef.nativeElement)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom);
      this.svg = svgEle
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');


      if (false) {
        this.svg.selectAll('image').data([0]).enter()
          .append('svg:image')
          .attr('x', -2 * margin.left / 3)
          .attr('y', height + (margin.top))
          .attr('width', 18)
          .attr('height', 18)
          .attr('xlink:href', '/assets/img/zoomin.png')
          .on('click', function() {
            me.zoom.emit({});
          });
      }

      // Add an x-axis label.
      this.svg.append('text')
        .attr('text-anchor', 'middle')  // this makes it easy to centre the text as the transform is applied to the anchor
        .attr('transform', 'translate(' + (width / 2) + ',' + (height + 30) + ')')  // centre below axis
        .text(this._data.xAxisName);

      // Add a y-axis label.
      this.svg.append('text')
        .attr('text-anchor', 'middle')  // this makes it easy to centre the text as the transform is applied to the anchor
        .attr('transform', 'translate(-30,' + (height / 2) + ')rotate(-90)')  // text is drawn off the screen top left, move down and out and rotate
        .text(this._data.yAxisName);

      const gX = this.svg.append('g')
        .attr('class', 'x2 axis')
        .attr('transform', 'translate(0,' + (height + 15) + ')')
        .call(this.xAxis);

      const gX2 = this.svg.append('g')
        .attr('class', 'x axis')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis2);

      const gY = this.svg.append('g')
        .attr('class', 'y axis')
        .call(this.yAxis);


      const initialData = this._data.interpolateData(this._data.startdate);
      // me.logger.debug(initialData);
      this.dot = this.svg.append('g')
        .attr('class', 'dots')
        .selectAll('.dot')
        .data(initialData, function(d) {
          return d.name;
        })
        .enter()
        .append('g')
        .attr('class', 'dot')
        .each(function(xxx) {
          d3.select(this)
            .selectAll('circle')
            .data(xxx.points)
            .enter()
            .append('circle')
            .style('stroke', (d) => 'transparent')
            .style('fill', function(d: any) {
              return d.colorType;
            })
            .attr('cx', function(d: any) {
              if (d && d.x && d.x != 0) {
                return me.xScale(d.x);
              }
              return me.xScale(0);
            })
            .attr('cy', function(d: any) {
              if (d && d.y) {
                return me.yScale(d.y);
              }
              return me.yScale(0);
            })
            .attr('r', function(d) {
              return 5;
            });

        });
      this.text = this.svg.append('g')
        .selectAll('.texts')
        .data(initialData)
        .enter()
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'top')
        .text(function(d) {
          return d.name;
        })
        .attr('class', 'text')
        .call((dot) => me.positionText(dot));


      if (this._bands && this._bands.length > 0) {
        console.log('***************************************************');
        console.log('BANDS', this._bands);
        console.log('***************************************************');
        const textBands = [];
        this._bands.forEach(c => {
          const txtarr = c.text.split('\n');
          txtarr.forEach((t, idx) => {
            if (t && t.length) {
              const obj = Object.assign({}, c);
              obj.text = t;
              obj.id = obj.id + '-' + idx;
              textBands.push(obj);
            }
          });
        });
        console.log(textBands);


        this.overlay = svgEle
          .append('g')
          .attr('class', 'overlay');
        this.overlay
          .selectAll('text')
          .data(textBands)
          .enter()
          .append('text')
          .attr('text-anchor', 'start')
          .attr('visibility', 'hidden')
          .attr('transform', function(d, i) {
            return 'translate(70,40)';
          })
          .text(function(d) {
            return d.text;
          });
      } else {
        console.log('***************************************************');
        console.log('NO BANDS');
        console.log('***************************************************');
      }


      if (this.enableZoomFunction) {

        const zoomFunction = () => {
          // create new scale ojects based on event
          const new_xScale = d3.event.transform.rescaleX(me.xScale);
          const new_yScale = d3.event.transform.rescaleY(me.yScale);

          // update axes
          gX.call(me.xAxis.scale(new_xScale));
          gY.call(me.yAxis.scale(new_yScale));

          // update circle
          // me.dot.attr("transform", "translate("+d3.event.transform.x+","+d3.event.transform.y+")");
          // me.text.attr("transform", "translate("+d3.event.transform.x+","+d3.event.transform.y+")");
          me.dot.attr('transform', d3.event.transform);
          me.text.attr('transform', d3.event.transform);
        };
        const zoom = d3.zoom()
          .scaleExtent([0.5, 10])
          .translateExtent([[0.5, 0.5], [width + 100, height + 100]])
          .on('zoom', zoomFunction);
        const innerSpace = this.svg.call(zoom);

        const view = innerSpace.append('rect')
          .attr('class', 'zoom')
          .attr('width', width)
          .attr('height', height)
          .call(zoom);
      }
    }
  }

  positionDot(dot) {
    const me = this;

    dot
      .attr('fill-opacity', function(d) {
        if (d.opa || (d.opa == 0)) {
          return d.opa;
        }
        return 1;
      })
      .attr('fill', (d) => d.colorType)
      .attr('class', (d) => d.type)

      .attr('cx', function(d) {
        if (d && d.x) {
          return me.xScale(d.x);
        }
        return me.xScale(0);
      })
      .attr('cy', function(d) {
        if (d && d.y) {
          return me.yScale(d.y);
        }
        return me.yScale(0);
      })
      .attr('r', function(d) {
        return 5;
      });
  }

  positionText(dot) {
    const me = this;
    dot
      .attr('x', function(d) {
        const x = me.xScale(d.points[d.points.length - 1].x);
        return x || 0;
      })
      .attr('y', function(d) {
        let y = me.yScale(d.points[d.points.length - 1].y);
        if (y) {
          y -= 10;
        }
        return y || 0;
      });

  }


  position(dot) {
    const me = this;
    dot.each(function(xxx) {
      d3.select(this)
        .selectAll('circle')
        .data(xxx.points)
        .call(function(d) {
          me.positionDot(d);
        });

    });
  }

  displayweek(week, date) {
    try {
      const me = this;
      const newData = this._data.interpolateData(week);
      me.dot.data(newData, (d) => d.name)
        .call(function(d) {
          me.position(d);
        });
      this.text.data(newData, (d) => d.name).call((dot) => me.positionText(dot));


      if (this.overlay) {
        const time = date.getTime();
        const count = 0;
        this.overlay
          .selectAll('text')
          .filter((d) => {
            let totime = DynData.str2Date(d.to);
            if (d.from == d.to) {
              totime = (DynData.str2Date(d.from) + (1000 * 24 * 3600 * 10));
            }
            return !((DynData.str2Date(d.from) < time) && (totime > time));
          }).attr('visibility', 'hidden');
        this.overlay
          .selectAll('text')
          .filter((d) => {
            let totime = DynData.str2Date(d.to);
            const singleDayEvent = d.from == d.to;
            if (singleDayEvent) {
              totime = (DynData.str2Date(d.from) + (1000 * 24 * 3600 * 10));
            }
            const visible = (DynData.str2Date(d.from) < time) && (totime > time);
            // console.log(time, d.from, d.to, d.text, visible, singleDayEvent);
            return visible;
          })
          .attr('visibility', 'visible')
          .attr('transform', function(d, i) {
            return 'translate(70,' + (40 + (i * 15)) + ')';
          });
      }
    } catch (e) {
      console.log('error', e);
    }
  }


}
