import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import {EventManager} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {SelectedChartAction} from '../../backend/analytics.state';
import {MediaObserver} from '@angular/flex-layout';


export declare let require: any;


function syncExtremes(charts, thisChart, e) {
  if (e.trigger !== 'syncExtremes') { // Prevent feedback loop
    Highcharts.each(charts, function(chart) {
      if (chart !== thisChart) {
        if (chart.xAxis[0].setExtremes) { // It is null while updating
          chart.xAxis[0].setExtremes(
            e.min,
            e.max,
            undefined,
            false,
            {trigger: 'syncExtremes'}
          );
        }
      }
    });
  }
}

@Component({
  selector: 'synced-hc',
  template: ''
})
export class SyncedHighChart implements AfterViewInit, OnInit, OnDestroy {

  _data: any;
  options: any[];
  _chart: any;
  charts: any[] = [];

  @Output()
  action: EventEmitter<SelectedChartAction> = new EventEmitter<SelectedChartAction>();

  @Input()
  set data(o: any) {
    this._data = o;
    const me = this;
    this.options = [];
    if (o && o.length) {
      o.forEach((value, index, array) => {
        this.options.push(value);
      });
    }
    this.redraw();
  }

  constructor(public chartEl: ElementRef, private eventManager: EventManager,
              private renderer: Renderer2, @Inject(DOCUMENT) private document,
              private _observableMedia: MediaObserver) {
    const me = this;
    this._observableMedia.asObservable().subscribe(value => {
      if (me._chart) {
        me.redraw();
      }
    });
  }

  ngOnInit(): void {
  }

  onResize() {
    this.redraw();
  }


  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchemove', ['$event'])
  @HostListener('document:touchestart', ['$event'])
  evt(e) {
    let chart, point, i, event;
    for (i = 0; i < this.charts.length; i = i + 1) {
      chart = this.charts[i];
      event = chart.pointer.normalize(e);
      point = chart.series[0].searchPoint(event, true);
      if (point) {
        point.highlight(e);
      }
    }
  }

  public ngAfterViewInit() {
    this.eventManager.addEventListener(this.chartEl.nativeElement, 'onresize', function(e) {
      console.log('resize', e);
    });
    /**
     * Override the reset function, we don't need to hide the tooltips and
     * crosshairs.
     */
    Highcharts.Pointer.prototype.reset = function() {
      return undefined;
    };

    /**
     * Highlight a point by showing tooltip, setting hover state and draw crosshair
     */
    Highcharts.Point.prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      this.series.chart.tooltip.refresh(this);
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
    this.redraw();
  }

  redraw() {
    const me = this;
    if (this.chartEl && this.chartEl.nativeElement) {
      const ne = this.chartEl.nativeElement; // .children[0];
      ne.innerHTML = '';
      me.charts = [];
      if (this.options) {
        this.options.forEach(function(dataset, i) {
          const chartDiv = document.createElement('div');
          chartDiv.className = 'chart';
//                    document.getElementById('container').appendChild(chartDiv);
          me.renderer.appendChild(me.chartEl.nativeElement, chartDiv);

          dataset.xAxis.crosshair = true;
          dataset.xAxis.events = {
            setExtremes: function(e) {
              syncExtremes(me.charts, this, e);
            }
          };
          // dataset.tooltip.positioner = function () {return {x: 0, y: 10};};
          dataset.tooltip.shared = false;
          dataset.tooltip.borderWidth = 0;
          dataset.tooltip.shadow = false;
          dataset.chart.height = 400 / me.options.length;

          me.charts.push(Highcharts.chart(chartDiv, dataset));
        });
      }

    }
  }

  public ngOnDestroy() {
    if (this._chart) {
      this._chart.destroy();
    }
  }
}
