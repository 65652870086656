import {AfterViewInit, Component, ElementRef, Input, OnDestroy} from "@angular/core";


@Component({
    selector: 'word-cloud',
    template: ''
})
export class WordCloudComponent implements AfterViewInit, OnDestroy {
    __data: any;

    _data: any[] = [];
    sizeScale: any;
    opaScale: any;
    cl: any;
    layout: any;
    weekInterpolation: any;


    @Input()
    width:number = null;
    @Input()
    height:number = null;

    opaCol = 3;
    padding: number = 80;

    get data() {
        return this.__data;
    }

    @Input()
    set data(d: any) {
        this.__data = d;
        this._data = d.words;
        this.draw();
    }


    constructor(private elementRef: ElementRef) {
    }

    ngAfterViewInit() {
        let me = this;
        if (this._data) {
            me.draw();
        }
    }


    drawCloud(t) {
        let me = this;
        if (!this._data) {
            return false;
        }
        this.cl
            .selectAll("text")
            .data(me._data);
    }


    draw() {
        let me = this;

        this.elementRef.nativeElement.innerHTML = '';
        if (this._data) {
            let width = this.width || 400;//this.elementRef.nativeElement.getBoundingClientRect().width;
            let height = this.height || 400;
            let minS = d3.min(this._data, (b) => b.size);
            let maxS = d3.max(this._data, (b) => b.size);

            me.sizeScale = d3.scaleLinear().domain([minS, maxS]).range([10, 50]);


            this.layout = d3.layout.cloud()
            //.size([width - me.padding, height - me.padding])
                .size([width, height])
                .font((d) => "Adwired")
                .words(me._data)
                .padding(5)
                .rotate(function () {
                    return ~~(Math.random() * 2) * 90;
                })
                .fontSize(function (d) {
                    return me.sizeScale(d.size);
                })
                .on("end", (t) => me.initCloud(t));
            this.layout.start();
        }
    }

    initCloud(words) {
        let me = this;
        let width = 400;//this.elementRef.nativeElement.getBoundingClientRect().width;
        let height = 400;
        this.cl = d3.select(this.elementRef.nativeElement)
            .append("svg")
            .attr("width", this.layout.size()[0])
            .attr("height", this.layout.size()[1])
            .attr("class", "wordcloud")
            .append("g")
            .attr("transform", "translate(" + this.layout.size()[0] / 2 + "," + this.layout.size()[1] / 2 + ")");
        // var color = d3.scaleLinear()
        //     .domain([0,1,2,3,4,5,6,10,15,20,100])
        //     .range(["#ddd", "#ccc", "#bbb", "#aaa", "#999", "#888", "#777", "#666", "#555", "#444", "#333", "#222"]);
        var color = d3.scaleOrdinal(d3["schemeCategory20c"]);
        this.cl
            .selectAll("text")
            .data(words)
            .enter().append("text")
            .style("font-size", function (d) {
                return d.size + "px";
            })
            .style("font-family", "Adwired")
            //.style("fill", function (d, i) {return d.colorType;})
            .style("fill", function (d, i) {
                return color((Math.random() * 2) * 90);
            })
            .attr("text-anchor", "middle")
            .attr("transform", function (d) {
                return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
            })
            .text(function (d) {
                return d.text;
            });

    }

    ngOnDestroy(): void {
    }


}
