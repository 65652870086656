import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DownloadService} from './downloadservice';


@Component({
  selector: 'adw-download',
  templateUrl: './download.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadComponent implements OnInit {

  message = null;


  constructor(private downloadServce: DownloadService, private cd: ChangeDetectorRef) {
    let me = this;
    downloadServce.message.subscribe((value: any) => {
      if (value) {
        me.message = value.html || 'Downloading....';
      } else {
        me.message = null;
      }
      cd.markForCheck();
    });

  }

  ngOnInit() {
  }

  close() {
    console.log('DOWNLOAD: Close!!!!!!!!!!!!!!!!!!')
    this.downloadServce.close();
    this.message = null;
  }
}
