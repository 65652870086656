import {NgModule} from '@angular/core';

import {AdwHighchart, AdwHighchartDialog} from './adw-hc';
import {DynCloudComponent} from './dyncloud.component';
import {DynMatrixComponent} from './dynmatrix.component';
import {CommonModule} from '@angular/common';
import {DynCombiDialog, DynComboComponent} from 'app/utils/highchart/dyncombi.component';
import {LoggerModule} from '../logging';
import {FormsModule} from '@angular/forms';
import {AdwMultiHighchart} from './multi-hc';
import {NouisliderModule} from 'ng2-nouislider';
import {AdwHighchartSingle} from './adw-hc-single';
import {ZoomModule} from '../zoom';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {DataTableContentComponent} from './data-table';
import {WordCloudComponent} from './wordcloud.component';
import {D3Gauge} from './d3-gauge';
import {SyncedHighChart} from './synced-hc';
import {RankingChartComponent} from './ranking-chart';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [CommonModule, LoggerModule, NouisliderModule, FormsModule, ZoomModule, MatDialogModule, FlexLayoutModule],
    declarations: [
        AdwHighchartSingle, D3Gauge, AdwHighchart, DynCloudComponent, DynMatrixComponent, DynComboComponent,
        AdwMultiHighchart, RankingChartComponent, AdwHighchartDialog,
        DataTableContentComponent, DynCombiDialog, WordCloudComponent, SyncedHighChart
    ],
    exports: [
        AdwHighchartSingle, D3Gauge, AdwHighchart,
        RankingChartComponent, DynCloudComponent, DynMatrixComponent, DynComboComponent, AdwMultiHighchart, AdwHighchartDialog,
        DataTableContentComponent, DynCombiDialog, WordCloudComponent, SyncedHighChart
    ]
})
export class ChartModule {
}

export {
  AdwHighchart, DynCloudComponent, DynMatrixComponent
};
