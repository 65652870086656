import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ScriptService} from '@adw/services/script.service';

function isNumber(value: string | number): boolean {
  return ((value != null) && !isNaN(Number(value.toString())));
}

@Component({
  selector: 'adw-d3-gauge',
  template: '',
  styles: [':host {margin: auto; width: 200px; height: 200px; display: block}']
})
export class D3Gauge implements AfterViewInit, OnInit, OnDestroy {

  private _data: any;

  private scriptsLoaded: boolean = false;

  @Input()
  set data(d) {
    this._data = d;
    this.update();
  }

  get data() {
    return this._data;
  }

  ngOnDestroy(): void {
  }

  deg2rad(deg) {
    return deg * Math.PI / 180;
  }

  constructor(private scriptService: ScriptService, private elementRef: ElementRef) {
    this.scriptService.load('d3',
      '//cdnjs.cloudflare.com/ajax/libs/d3-cloud/1.2.5/d3.layout.cloud.min.js',
      '//cdnjs.cloudflare.com/ajax/libs/d3/4.13.0/d3.min.js').then(value => {
      this.scriptsLoaded = true;
      this.update();
    });
  }

  nFormatter(num, digits): string {
    const si = [
      {value: 1, symbol: ''},
      {value: 1E3, symbol: 'k'},
      {value: 1E6, symbol: 'M'},
      {value: 1E9, symbol: 'G'},
      {value: 1E12, symbol: 'T'},
      {value: 1E15, symbol: 'P'},
      {value: 1E18, symbol: 'E'}
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
  }

  ngOnInit(): void {
    this.update();
  }

  public ngAfterViewInit() {
  }

  private update() {
    this.elementRef.nativeElement.innerHTML = '';
    if (!this._data || !this.scriptsLoaded) {
      return;
    }
    const me = this;
    const config = {size: 200, ringInset: 20, ringWidth: 5, minAngle: -90, maxAngle: 90, transitionMs: 750};
    const r = config.size / 2;
    const primaryColor = '#0089c0';
    const secondaryColor = '#222222';
    const val1 = this._data.value;
    const val2 = this._data.previous;

    const max = Math.max(val1, val2);
    let val1Rad = 0;
    let val2Rad = 0;
    let percent = 0;
    if (max > 0) {
      percent = (100 * (val1 - val2) / max);
      val1Rad = (val1 * 175 / max) - 88; // zwischen -88 und 87;
      val2Rad = 267 - (val2 * 175 / max); // zwischen 92 und 267
    }
    const tickData = [
      {start: -88, end: 87, color: primaryColor, opacity: 0.5},
      {start: -88, end: val1Rad, color: primaryColor},
      {start: 92, end: 267, color: secondaryColor, opacity: 0.5},
      {start: val2Rad, end: 267, color: secondaryColor}
    ];

    const arc = d3.arc()
      .innerRadius(function(d, i) {
        return (r - config.ringWidth - config.ringInset);
      })
      .outerRadius(function(d, i) {
        return r - config.ringInset;
      })
      .startAngle(function(d, i) {
        return me.deg2rad(d.start);
      })
      .endAngle(function(d, i) {
        return me.deg2rad(d.end);
      });

    const svg = d3.select(this.elementRef.nativeElement)
      .append('svg:svg')
      .attr('class', 'gauge')
      .attr('width', config.size)
      .attr('height', config.size);

    svg.append('svg:defs')
      .append('svg:marker')
      .attr('id', 'arrow')
      .attr('markerWidth', 4)
      .attr('markerHeight', 4)
      .attr('viewBox', '0 0 12 12')
      .attr('refX', 6)
      .attr('refY', 6)
      .attr('orient', 'auto')
      .append('svg:path')
      .attr('d', 'M 0 0 12 6 0 12 3 6');

    const centerTx = 'translate(' + r + ',' + r + ')';
    const arcs = svg.append('g')
      .attr('class', 'arc')
      .attr('transform', centerTx);
    arcs.selectAll('path')
      .data(tickData)
      .enter().append('path')
      .attr('fill', function(d, i) {
        return d.color;
      })
      .style('opacity', (d) => d.opacity || 1.0)
      .attr('d', arc);


    const lg = svg.append('g')
      .attr('class', 'label');


    const baseFontSize = config.size * 16 / 200;
    const data = [
      {y: -baseFontSize, text: val1, fontSize: (2 * baseFontSize) + 'px', color: primaryColor},
      {y: baseFontSize, text: percent.toFixed(1) + '%', fontSize: (1.5 * baseFontSize) + 'px', color: secondaryColor},
      {y: 2 * baseFontSize, text: 'Compared to previous', fontSize: (0.5 * baseFontSize) + 'px', color: secondaryColor},
      {y: 3 * baseFontSize, fontSize: baseFontSize + 'px', text: val2, color: secondaryColor},
    ];
    const txt = lg
      .selectAll('text')
      .data(data)
      .enter()
      .append('text')
      .attr('transform', centerTx)
      .attr('y', (d) => d.y)
      .attr('text-anchor', 'middle')
      .attr('font-size', (d) => d.fontSize)
      .attr('fill', (d) => d.color || 'black')
      .append('tspan')
      .text((d) => d.text)
      .attr('class', 'title');

    // rotate: 135=equal, 90=lower, 45=very low, 180=higher, 225=very high
    let rotate = 135;
    if (Math.abs(percent) < 5) {
      rotate = 135;
    } else if (percent > 40) {
      rotate = 225;
    } else if (percent > 5) {
      rotate = 180;
    } else if (percent < -40) {
      rotate = 45;
    } else if (percent < -5) {
      rotate = 90;
    }
    const lineSize = 10;
    const offset = 0;
    const line = svg.append('line')
      .attr('transform', `translate(${r - 50},${r}) rotate(${rotate},${(lineSize / 2)},${(lineSize / 2)})`)
      .attr('x1', offset)
      .attr('y1', offset)
      .attr('x2', lineSize)
      .attr('y2', lineSize)
      .attr('stroke-width', 2)
      .attr('stroke', 'black')
      .attr('marker-end', 'url(#arrow)');
    txt.transition()
      .tween('text', function() {
        const selection = d3.select(this);    // selection of node being transitioned
        const start = 0;
        const end = d3.select(this).text();
        if (isNumber(end)) {
          const interpolator = d3.interpolateNumber(start, end);
          return function(t) {
            selection.text(me.nFormatter(Math.round(interpolator(t)), 1));
          };
        }
        return function(t) {
          return t;
        };
      })
      .duration(config.transitionMs);

  }
}
