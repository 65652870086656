import {Injectable, Optional} from '@angular/core';
import * as moment from 'moment';

export class LoggerConfig {
  level: string;
  enableDarkTheme?: boolean;
}

const Levels = [
  'TRACE',
  'DEBUG',
  'INFO',
  'LOG',
  'WARN',
  'ERROR',
  'OFF'
];

@Injectable()
export class NGXLogger {

  private _clientLogLevelIdx;
  private _isIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.userAgent.match(/Trident\//) || navigator.userAgent.match(/Edge\//);

  constructor(@Optional() private options: LoggerConfig) {
    this._clientLogLevelIdx = this._initLogLevel(this.options.level);
  }

  public changeLevel(level) {
    this._initLogLevel(level);
  }

  private _initLogLevel(level) {
    level = level ? level.toUpperCase() : level;
    level = Levels.indexOf(level);
    return level === -1 ? Levels.indexOf('INFO') : level;
  }

  private _logIE(level: string, message: any) {
    message = `${moment.utc().format()} [${level}] ${message}`;

    if (level === 'WARN') {
      console.warn(message);
    } else if (level === 'ERROR') {
      console.error(message);
    } else if (level === 'INFO') {
      console.info(message);
    } else {
      console.log(message);
    }
  }

  private _log(level: string, message: any[]) {

    //if no message or the log level is less than the environ
    if (!message || Levels.indexOf(level) < this._clientLogLevelIdx) {
      return;
    }

    if (this._isIE) {
      return this._logIE(level, message);
    }

    let color1;

    switch (level) {
      case 'TRACE':
        color1 = 'blue';
        break;
      case 'DEBUG':
        color1 = 'teal';
        break;
      case 'INFO':
      case 'LOG':
        color1 = 'gray';
        break;
      case 'WARN':
      case 'ERROR':
        color1 = 'red';
        break;
      case 'OFF':
      default:
        return;
    }

    const defaultColor = this.options.enableDarkTheme ? 'white' : 'black';
    let output = [...message.slice(1), moment.utc().format(), level];

    console.log(message[0], ...output);
  }

  trace(...message: any[]) {
    this._log('TRACE', message);
  }

  debug(...message: any[]) {
    this._log('DEBUG', message);
  }

  info(...message: any[]) {
    this._log('INFO', message);
  }

  log(...message: any[]) {
    this._log('LOG', message);
  }

  warn(...message: any[]) {
    this._log('WARN', message);
  }

  error(...message: any[]) {
    this._log('ERROR', message);
  }
}
