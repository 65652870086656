import {ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthenticationService} from './auth.service';
import {AuthGuard} from './auth.guard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './jwt.interceptor';
import {JWT_OPTIONS} from './jwtoptions.token';
import {JwtHelperService} from './jwthelper.service';

// export function authHttpServiceFactory(http: Http, options: RequestOptions) {
//     return new AuthHttp(new AuthConfig({
//         tokenName: 'token',
//         headerName:'X-Authorization',
//
//         tokenGetter: (() => {
//             return localStorage.getItem('token');
//         }),
//         globalHeaders: [{'Content-Type':'application/json'}, {'X-Requested-With': "XMLHttpRequest"}],
//     }), http, options);
// }

export interface JwtModuleOptions {
  jwtOptionsProvider?: Provider,
  config?: {
    tokenGetter?: () => string | Promise<string>;
    headerName?: string;
    authScheme?: string;
    whitelistedDomains?: Array<string | RegExp>;
    throwNoTokenError?: boolean;
    skipWhenExpired?: boolean;
  }
}


@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: []
})
export class SecurityModule {

  static forRoot(options: JwtModuleOptions): ModuleWithProviders<SecurityModule> {
    return {
      ngModule: SecurityModule,
      providers: [
        AuthenticationService, AuthGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true
        },
        options.jwtOptionsProvider ||
        {
          provide: JWT_OPTIONS,
          useValue: options.config
        },
        JwtHelperService
      ]
    };
  }
}

