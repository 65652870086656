import {BehaviorSubject, of, timer} from 'rxjs';
import {catchError, concatMap, filter, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NGXLogger} from '../logging/logger.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';


@Injectable()
export class DownloadService {
  public message: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private lastWait: any;

  private started: boolean = false;

  constructor(private http: HttpClient,
              private logger: NGXLogger,
              private router: Router,
              private toastr: ToastrService) {

  }

  private post(url: string, param: any) {
    return this.http.post(url, param);
  }


  doLongRunningAction(html, url, downloadParams, blocking: boolean = true, toast: boolean = true) {
    return this.post('/api/action/test.json', downloadParams)
      .pipe(
        concatMap((value: any) => {
          if (blocking) {
            this.message.next({html: html});
          }
          return this.waitForAction(value.id, html, blocking, toast);
        })
      );
  }

  waitForAction(id, html, blocking, toast) {
    return timer(0, 500)
      .pipe(concatMap(() => this.post(`/api/status/action/${id}.json`, {})))
      .pipe(
        tap((response: any) => {
          if (blocking) {
            this.message.next({html: response.statusMessage || html});
          }
        }),
        filter((response: any) => response.done)
      )
      .pipe(
        tap((response: any) => {
          if (blocking) {
            this.message.next(null);
          }
          if (toast) {
            this.toastr.success(response.statusMessage || html, html);
          }
        }),
        take(1)
      );
  }

  downloadFromUrl(url, downloadParams) {
    this.started = true;
    this.message.next({html: 'Downloading...'});
    this.post(url, downloadParams)
      .pipe(catchError((err: Response, caught) => {
        this.handleError(err);
        return of(new Response('{}'));
      }))
      .subscribe((success: any) => {
        setTimeout(() => {
          this.waitForDownload(success);
        }, 1000);
      }, error => {
        this.message.next(null);
        this.toastr.error('Error in download');
      });

  }

  waitForDownload(data: any) {
    this.lastWait = data;
    this.logger.debug('wait for task', data);
    this.message.next({html: data.statusMessage || 'Processing...'});
    if (data.done) {
      let url = '/api/download/' + data.id;
      this.message.next({html: '<p>The File was produced and is now downloaded in a separate tab</p><p>If not please click on the <a href="' + url + '">link</a></p>'});
      this.toastr.success('Download', 'The File was produced and is now downloaded in a separate tab');
      window.location.href = (url);
      setTimeout(() => this.message.next(''), 1000);
    } else if (!data.id) {
      this.message.next({html: 'Error in download'});
    } else {
      if (this.started) {
        this.post('/api/status/download/' + data.id + '.json', {})
          .pipe(catchError((err: Response, caught) => {
            this.handleError(err);
            return of(new Response('{}'));
          }))
          .subscribe((data: any) => {
            this.waitForDownload(data);
          });
      }

    }
  }

  public handleError(error: Response | any, route?: string) {
    let errMsg: string = 'Unknown Error';
    try {
      if (error instanceof Response) {
        const body = error.json() || '';
        const err = body['error'] || JSON.stringify(body);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      } else if (typeof error.json === 'function') {
        const body = error.body ? JSON.parse(error.body) : (error.json() || '');
        const err = body['message'] || JSON.stringify(body);
        errMsg = `${error['status'] || 'Error'} - ${error['statusText'] || ''} ${err}`;
      } else {
        errMsg = error.message ? error.message : error.toString();
      }
    } catch (e) {
      errMsg = 'Unknown Error';
    }
    this.toastr.error(errMsg);
    if (route) {
      this.router.navigate([route]);
    }

  }


  close() {
    this.started = false;
    console.log('Calling: ' + '/api/close/download/' + this.lastWait.id + '.json');
    this.post('/api/close/download/' + this.lastWait.id + '.json', {}).subscribe((data: any) => {
      this.started = false;
      this.message.next(null);
    });
  }
}


