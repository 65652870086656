import {AfterViewInit, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {mergeDeep} from '../utils';
import {highchartFunctions} from './adw-hc-single';
import {SelectedChartAction} from '../../backend/analytics.state';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {NGXLogger} from '../logging/logger.service';


function addSeriesEntities(entities, series): any {
  if (series) {
    const userOptions = series.userOptions;
    if (userOptions && userOptions.entityType && userOptions.id) {
      const item = {entityType: userOptions.entityType, value: userOptions.id};
      entities.push(item);
      return item;
    }
  }
  return null;
}

function getEntities(chartInstance, e) {
  const entities = [];
  if (!chartInstance) {
    return entities;
  }
  try {
    if (e) {
      if (!e.point) {
        this.logger.debug('getEntities() --> no point there');
        return entities;
      }
      if (chartInstance.hoverPoint) {
        const hp = chartInstance.hoverPoint; // .hoverPoints.sort(hp => {return hp.dist;})[0];
        addSeriesEntities(entities, hp.series);
      }
      addSeriesEntities(entities, e.series);
      if (e.point) {
        const pe = addSeriesEntities(entities, e.point.series);
        const options = e.point.options;
        if (options && options.entityType && options.id) {
          entities.push({
            entityType: options.entityType,
            value: options.id,
            from: toDate(options.x),
            to: toDate(options.x)
          });
        }
        if (pe && options.x) {
          pe.from = toDate(options.x);
          pe.to = toDate(options.x);
        }
      }
    }
  } catch (e) {
    this.logger.debug('Problems detecting entities', e);
  }
  return entities;
}

function toDate(x) {
  const d = new Date().getTime();
  if (Math.abs(d - x) < (1000 * 24 * 3600 * 365 * 10)) {
    return x / (1000 * 24 * 3600);
  }
  return null;
}

@Component({
  selector: 'adw-hc-dialog',
  template: `
    <adw-zoom (close)="dialog.close()" [data]="zoomOptions" class="chartZoom">
      <adw-hc-single (events)="onEvent($event)" (loadChart)="onLoadSingle($event)"
                     [options]="zoomOptions"></adw-hc-single>
    </adw-zoom>`
})
export class AdwHighchartDialog {
  zoomOptions: any = null;

  @Output()
  public action: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialog: MatDialogRef<AdwHighchartDialog>, private logger: NGXLogger) {
  }

  onLoadSingle($event) {
    const chart = $event.single;
    const chartInstance = $event.chartInstance;
    const options = $event.options;
    const me = this;
    if (options.load) {
      highchartFunctions[options.load](chartInstance);
    }
    if (chartInstance && chartInstance.series) {
      Highcharts.addEvent(chartInstance.container, 'click', function(e) {
        try {
          me.logger.debug('click@highcharts@series', this, chartInstance, e);
          const entities = getEntities(chartInstance, e);
          if (entities && entities.length) {
            const action = {action: 'entity', entities: entities};
            me.action.emit(action);
          }
        } catch (e) {
          me.logger.debug('Problems in click@highcharts@series', e);
        }
      });
    }
  }

  onEvent($event: any) {
    this.logger.debug('event on dialog', $event);
    if ($event.options == 'selection') {
      const from = Math.floor($event.event.xAxis[0].min / (1000 * 24 * 3600));
      const to = Math.floor($event.event.xAxis[0].max / (1000 * 24 * 3600));
      const entity = {type: '', value: '', from: from, to: to};
      const action = {action: 'entity', entities: [entity]};
      this.logger.debug('adw-hc:selection', action);
      this.action.emit(action);
    } else {
      this.action.emit($event);
    }
  }
}


@Component({
  selector: 'adw-hc',
  template: `
    <adw-hc-single [height]="height" [width]="width" [options]="_options"
                   (events)="onEvent($event)" (loadChart)="onLoadChart($event)"></adw-hc-single>
  `,
  styleUrls: ['./adw-hc.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdwHighchart implements AfterViewInit, OnInit, OnDestroy {
  @HostBinding('class.eventsHidden') eventsHidden: boolean;

  @Input()
  set options(o: any) {
    this._options = o;
  }

  get options(): any {
    return this._options;
  }

  @Input()
  width: number = null;
  @Input()
  height: number = null;

  _options: any = null;
  zoomOptions: any = null;

  @Output()
  action: EventEmitter<SelectedChartAction> = new EventEmitter<SelectedChartAction>();

  @Input()
  showZoom: boolean = true;

  @Input()
  zoomPosition: string = 'bl';

  ngOnDestroy(): void {
  }


  constructor(private dialog: MatDialog, private logger: NGXLogger) {
  }

  ngOnInit(): void {
  }

  onLoadSingle($event) {
    const chart = $event.single;
    const chartInstance = $event.chartInstance;
    const options = $event.options;
    if (options.load) {
      highchartFunctions[options.load](chartInstance);
    }
  }

  onEvent($event) {
    if ($event.options == 'selection') {
      const from = Math.floor($event.event.xAxis[0].min / (1000 * 24 * 3600));
      const to = Math.floor($event.event.xAxis[0].max / (1000 * 24 * 3600));
      const entity = {type: '', value: '', from: from, to: to};
      const action = {action: 'entity', entities: [entity]};
      this.logger.debug('adw-hc:selection', action);
      this.action.emit(action);
    }
    if ($event.options == 'legendItemClick') {
      // let from = Math.floor($event.event.xAxis[0].min / (1000 * 24 * 3600));
      // let to = Math.floor($event.event.xAxis[0].max / (1000 * 24 * 3600));
      // let entity = {type: '', value: '', from: from, to: to};
      // let action = {action: 'entity', entities: [entity]};
      this.logger.debug('adw-hc:legendItemClick');
//            this.action.emit(action);
    }
  }

  onLoadChart($event) {
    const me = this;
    const chart = $event.single;
    const chartInstance = $event.chartInstance;
    const options = $event.options;

    me.onLoadSingle($event);
    if (chartInstance && chartInstance.series) {
      Highcharts.addEvent(chartInstance.container, 'click', function(e) {
        me.logger.debug('click@highcharts@container', this, chartInstance, e);
        if (!e.point) {
          me.logger.debug('click@highcharts@container --> no point there');
          return;
        }
        const entities = getEntities(chartInstance, e);
        if (e.path && e.path.length) {
          const data_link = e.path[0].getAttribute('data-link');
          if (data_link) {
            me.action.emit({action: 'link', description: data_link, buttonClass: ''});
          }
        }
        let p = null;
        if (e.xAxis && e.xAxis.length && e.yAxis && e.yAxis.length && e.yAxis[0].value > 0) {
          chartInstance.series.forEach(function(s) {
            const point = s.points[Math.round(e.xAxis[0].value)];
            if (point && point.options.link) {
              p = point;
            }
          });
          if (p) {
            me.action.emit({action: 'zoom_section', description: p.options.link, buttonClass: ''});
          }
        }

        if (entities.length) {
          const action = {action: 'entity', entities: entities};
          me.logger.debug('adw-hc:emit', action);
          me.action.emit(action);
        }

      });
      chartInstance.series.forEach(function(s) {
        s.options.point.events = {
          click: function() {
            try {
              if (me.options.link) {
                me.action.emit({action: 'zoom_section', description: me.options.link, buttonClass: ''});
              }
            } catch (e) {
              me.logger.debug('problems with click on point event', e);
            }
          }
        };
      });
    }
    if (this._options && this._options.xAxis) {
      const plotLines =  (this._options.xAxis.plotLines && this._options.xAxis.plotLines.length > 0);
      const plotBands =  (this._options.xAxis.plotBands && this._options.xAxis.plotBands.length > 0);
      if (plotBands || plotLines) {
        const renderer = chartInstance.renderer;
        const imgData = 'data:image/svg+xml;base64,' + window.btoa('<svg version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Icons" overflow="hidden"><g><path xmlns="http://www.w3.org/2000/svg" d="m494.8,241.4l-50.6-49.4c-50.1-48.9-116.9-75.8-188.2-75.8s-138.1,26.9-188.2,75.8l-50.6,49.4c-11.3,12.3-4.3,25.4 0,29.2l50.6,49.4c50.1,48.9 116.9,75.8 188.2,75.8s138.1-26.9 188.2-75.8l50.6-49.4c4-3.8 11.7-16.4 0-29.2zm-238.8,84.4c-38.5,0-69.8-31.3-69.8-69.8 0-38.5 31.3-69.8 69.8-69.8 38.5,0 69.8,31.3 69.8,69.8 0,38.5-31.3,69.8-69.8,69.8zm-195.3-69.8l35.7-34.8c27-26.4 59.8-45.2 95.7-55.4-28.2,20.1-46.6,53-46.6,90.1 0,37.1 18.4,70.1 46.6,90.1-35.9-10.2-68.7-29-95.7-55.3l-35.7-34.7zm355,34.8c-27,26.3-59.8,45.1-95.7,55.3 28.2-20.1 46.6-53 46.6-90.1 0-37.2-18.4-70.1-46.6-90.1 35.9,10.2 68.7,29 95.7,55.4l35.6,34.8-35.6,34.7z"/></g></svg>');
        const img = renderer.image(imgData.replace(/<g>/gi, '<g fill=\'#274983\' stroke=\'#274983\'>'),
          chartInstance.chartWidth - 28, chartInstance.chartHeight - 28, 18, 18);
        img.add();
        img.css({'cursor': 'pointer', 'background': 'blue'});
        img.on('click', function () {
          me.eventsHidden = !me.eventsHidden;
        });
      }
    }

    if (me.showZoom) {
      const renderer = chartInstance.renderer;
      let img = null;
      if (me.zoomPosition == 'tl') {
        img = renderer.image('/assets/img/zoomin.png', 10, 10, 18, 18);
      } else {
        img = renderer.image('/assets/img/zoomin.png', 10, chartInstance.chartHeight - 28, 18, 18);
      }
      img.add();
      img.css({'cursor': 'pointer'});
      img.on('click', function($zoomClick) {
        try {
          me.logger.debug('zoomClick');
          $zoomClick.stopPropagation();
          const value = mergeDeep({}, options);
          value.chart.width = window.innerWidth;
          value.chart.height = window.innerHeight - 50;
          // me.zoom.emit(value);
          me.zoomOptions = value;
          const dialogRef = me.dialog.open(AdwHighchartDialog, {panelClass: 'nopadding-dialog'});
          dialogRef.componentInstance.zoomOptions = value;
          dialogRef.componentInstance.action.subscribe(action => {
            me.action.emit(action);
            dialogRef.close();
          });
          me.logger.debug('zoomClick: dialogopened');
        } catch (e) {
          me.logger.debug('Problems with click on img', e);
        }
        return false;

      });
    }
  }


  public ngAfterViewInit() {
  }


}
