import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'adw-ranking-chart',
  templateUrl: './ranking-chart.html',
  styleUrls: ['./ranking-chart.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RankingChartComponent {

  @Input()
  chart: any;

}


