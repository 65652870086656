import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './auth.service';
import {NGXLogger} from '../utils/logging/logger.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private logger: NGXLogger, private authService: AuthenticationService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    try {
      console.log(route);
      if (route.queryParams && route.queryParams['token']) {
        this.logger.info('Query Parameters: token=', route.queryParams['token'].substring(10));
        this.authService.setTempToken(route.queryParams['token']);
      }
      if (this.authService.tokenNotExpired()) {
        this.logger.debug('Token is valid');
        const roles = route.data['roles'];
        if (roles) {
          const roles2 = this.authService.getRoles();
          let ok = false;
          roles2.forEach((i) => {
            if (roles.indexOf(i) != -1) {
              ok = true;
            }
          });
          if (!ok) {
            this.logger.info('routing to login: missing roles: ', roles, roles2);
            this.router.navigate(['/pages/login']);
          }
          return ok;
        }
        return true;
      }
      this.logger.error('Token is NOT valid');
      this.router.navigate(['/pages/login']);
      return false;
    } catch (e) {
      console.log(e);
    }
    return false;
  }
}
