<div class="modal fade in show" *ngIf="data!=null" style="display:block;" tabindex="-1" role="dialog"
     aria-labelledby="Zoom" aria-hidden="true">
  <div class="zoom">
    <div class="modal-header zoom-header card-header primary-bg">
      <h4 class="modal-title bold-font pull-left">Zoom</h4>
      <button type="button" class="modal-close-button pull-right" aria-label="Close" (click)="onClose($event)">
        <i class="fa fa-close"></i>
      </button>
    </div>
    <div class="zoom-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
<div class="modal-backdrop fade in" *ngIf="data!=null" [class.show]="data!=null"></div>
