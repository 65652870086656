import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class AuthErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(error) {
    console.log('handleError', error);
    const router = this.injector.get(Router);
    if (error.status === 401 || error.status === 403) {
      console.log('there was an error ', error);
      router.navigate(['/pages/login']);
      return;
    }
    throw error;
  }
}
