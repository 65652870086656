import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {DatePipe, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {AppComponent} from './app.component';
import {ToastrModule} from 'ngx-toastr';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PopoverModule} from './utils/popover';
import {ChartModule} from './utils/highchart';
import {SecurityModule} from './security';
import {AppRoutingModule} from './app.routing';
import {LoggerModule} from './utils/logging';
import {DownloadModule} from './utils/download';
import {ZoomModule} from './utils/zoom';
import {ConfigurationModule} from './configuration';
import {HttpClient} from '@angular/common/http';
import {JWT_OPTIONS} from './security/jwtoptions.token';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {AuthErrorHandler} from './security/auth.error.handler';
import {FrontpageComponent} from './frontpage.component';
import {AngularMyDatePickerModule} from 'angular-mydatepicker';

export declare let require: any;


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/api/i18n/', '.json');
}

export function exportWindow() {
  return window['appconfig'] || {apiUrl: '/assets/json/', getInsteadOfPost: 1};
}


export function jwtOptionsFactory() {
  return {
    tokenGetter: (): string => {
      return localStorage.getItem('token');
    },
    skipWhenExpired: true,
    headerName: 'X-Authorization'
  };
}

export class MyErrorHandler implements ErrorHandler {
  handleError(error) {
    console.log('****************************************************************');
    console.log(error);
    console.log('****************************************************************');
  }
}

@NgModule({
  imports: [
    BrowserModule,
    MatMomentDateModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    LoggerModule.forRoot({level: 'INFO'}),
    SecurityModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory
      }
    }),
    DownloadModule.forRoot(),
    ZoomModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ChartModule,
    AngularMyDatePickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PopoverModule,
    ConfigurationModule.forRoot()
  ],
  declarations: [
    AppComponent, FrontpageComponent
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AuthErrorHandler
    },
    {provide: 'Window', useValue: window},
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: 'app.config', useFactory: exportWindow},
    DatePipe
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
