<div fxLayout="column" class="ranking">
    <div *ngFor="let b of chart.data" fxLayout="row" class="brand">
        <div fxFlex="0 0 25px">{{b.rank}}</div>
        <div fxFlex>{{b.name}}</div>
        <div fxFlex fxLayout="column">
            <div *ngFor="let c of b.entries" fxLayout="row">
                <div fxFlex>{{c.name}}</div>
                <div fxFlex="0 0 30px">{{c.score.toFixed(0)}}</div>
                <div fxFlex="0 0 100px" class="smileys">
                    <span *ngFor="let dummy of ' '.repeat(c.smiley).split('')" class="fa-smile fas"></span>
                </div>

            </div>
        </div>
        <div fxFlex="0 0 30px">{{b.score.toFixed(0)}}</div>
    </div>
</div>
