import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'adw-zoom',
  templateUrl: './zoom.html'
})
export class ZoomComponent implements OnInit {

  @Output()
  close: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  data: any = null;


  constructor() {
  }

  ngOnInit() {
  }

  onClose($event) {
    this.close.emit($event);
    this.data = null;
  }
}
