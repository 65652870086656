import * as moment from 'moment';

declare let d3: any;

export class DynData {


  static min(data, f, v) {
    return d3.min(data, function(d) {
      return d3.min(f(d), function(b) {
        return v(b);
      });
    });
  }


  static max(data, f, v) {
    return d3.max(data, function(d) {
      return d3.max(f(d), function(b) {
        return v(b);
      });
    });
  }

  static interpolateValues(values, what, week) {
    let i = DynData.bisect.left(values, week, 0, values.length - 1),
      a = values[i];
    if (i > 0) {

      let b = values[i - 1],
        t = ((week) - DynData.str2Date(a.date)) / (DynData.str2Date(b.date) - DynData.str2Date(a.date));
      return a[what] * (1 - t) + b[what] * t;
    }
    return a[what];
  }


  static interpolate(values, endPoint, number, delta) {
    let data = [];
    for (let step = 0; step < number; step++) {
      let week = endPoint - (number - step) * delta;
      let i = DynData.bisect.left(values, week, 0, values.length - 1);
      let v = null;
      if (i <= 0) {
        v = {x: values[0]['x'], y: values[0]['y']};
      } else {
        let a = values[i];
        let b = values[i - 1];
        let t = ((week) - DynData.str2Date(a.date)) / (DynData.str2Date(b.date) - DynData.str2Date(a.date));
        v = {
          x: a['x'] * (1 - t) + b['x'] * t,
          y: a['y'] * (1 - t) + b['y'] * t
        };
      }
      data.push(v);
    }
    return data;
  }


  static interpolateValues2(values, week, idx) {
    let i = DynData.bisect2.left(values, week, 0, values.length - 1),
      a = values[i];
    if (i > 0) {

      let b = values[i - 1],
        t = ((week) - DynData.str2Date(a[0])) / (DynData.str2Date(b[0]) - DynData.str2Date(a[0]));
      return a[idx] * (1 - t) + b[idx] * t;
    }
    return a[idx];
  }


  static str2Date(str: any) {
    return moment(str, 'YYYY-MM-DD').toDate().getTime();
  }

  static date2Str(str: any) {
    return d3.timeFormat('%B %d, %Y')(str);
  }


  static get bisect() {
    return d3.bisector(function(d) {
      return DynData.str2Date(d.date);
    });
  }

  static get bisect2() {
    return d3.bisector(function(d) {
      return DynData.str2Date(d[0]);
    });
  }

  static getValue(values, i, arr: string) {
    // let v = 0;
    // let count = 0;
    // let gleitend = 1;
    // let start = i - gleitend;
    // if (start < 0) start = 0;
    // for (let k = start; k < values.length && count < gleitend; k++) {
    //   v += values[k][1];
    //   count++;
    // }
    // return v / count;
    return values[i][arr];
  }

}
