import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {EventManager} from '@angular/platform-browser';
import {NGXLogger} from '../logging/logger.service';
import {Subject} from 'rxjs';


export declare let require: any;

const gx = function(c, fx) {
  return c.plotLeft + (c.plotWidth * fx);
};
const gy = function(c, fy) {
  return c.plotTop + (c.plotHeight * fy);
};

function weather(s) {
  const thunder = '<svg version="1.1" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Icons" overflow="hidden"><g><path d=" M 82.1 43.5 C 82.1 40.7 80.9 38.1 78.8 36.3 C 76.7 34.5 73.9 33.7 71.1 34.2 L 71.1 34 C 71.1 30.2 69.3 26.5 66.1 24.3 C 63 22 59 21.4 55.3 22.6 C 52.2 16.8 45.5 13.8 39.1 15.4 C 32.7 16.9 28.1 22.6 28 29.2 C 23.6 28.4 19.1 30.1 16.4 33.6 C 13.6 37.1 13 41.8 14.8 45.9 C 16.6 50 20.5 52.7 25 53 L 25 53 L 73 53 C 78.2 52.8 82.1 48.6 82.1 43.5 Z"/><path d=" M 56.1 65 L 47.6 65 L 50.8 57 L 43.2 57 L 40.1 70 L 46.5 70 L 42.6 81 Z"/><path d=" M 67.1 61 L 62.8 61 L 64.4 57 L 60.7 57 L 59.1 63.5 L 62.3 63.5 L 60.3 69 Z"/><path d=" M 38.1 61 L 33.8 61 L 35.4 57 L 31.7 57 L 30.1 63.5 L 33.3 63.5 L 31.3 69 Z"/></g></svg>';
  const sunny = '<svg version="1.1" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Icons" overflow="hidden"><g><rect x="4" y="45" width="18" height="6"/><rect x="74" y="45" width="18" height="6"/><circle cx="48" cy="48" r="22"/><rect x="45" y="74" width="6" height="18"/><rect x="45" y="4" width="6" height="18"/><rect x="14.3" y="69.7" width="18" height="6" transform="matrix(-0.7071,0.7071,-0.7071,-0.7071,91.0837,107.627)"/><rect x="63.7" y="20.3" width="18" height="6" transform="matrix(-0.7071,0.7071,-0.7071,-0.7071,140.679,-11.6295)"/><rect x="20.3" y="14.3" width="6" height="18" transform="matrix(-0.7071,0.7071,-0.7071,-0.7071,56.2507,23.2029)"/><rect x="69.7" y="63.7" width="6" height="18" transform="matrix(-0.7071,0.7071,-0.7071,-0.7071,175.511,72.7955)"/></g></svg>';
  const cloudy = '<svg version="1.1" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Icons" overflow="hidden"><g><rect x="56.8" y="10.5" width="4" height="12"/><rect x="70.5" y="21.8" width="12" height="4" transform="matrix(-0.7071,0.7071,-0.7071,-0.7071,147.466,-13.444)"/><rect x="77.8" y="39.5" width="12" height="4"/><rect x="74.5" y="53.2" width="4" height="12" transform="matrix(-0.7071,0.7071,-0.7071,-0.7071,172.477,46.9223)"/><rect x="27.8" y="39.5" width="12" height="4"/><rect x="39.2" y="17.8" width="4" height="12" transform="matrix(-0.7071,0.7071,-0.7071,-0.7071,87.099,11.5672)"/><path d=" M 64.8 66.5 C 64.3 66.5 63.8 66.5 63.3 66.6 C 63.3 66.6 63.3 66.5 63.3 66.5 C 63.3 62.7 61.5 59 58.3 56.8 C 55.2 54.5 51.2 53.9 47.5 55.1 C 44.4 49.3 37.7 46.3 31.3 47.9 C 24.9 49.4 20.4 55.1 20.2 61.7 C 16 60.9 11.5 62.5 8.7 66 C 6 69.5 5.4 74.3 7.2 78.3 C 9 82.4 12.9 85.1 17.4 85.4 L 65.4 85.5 C 70.6 85.4 74.8 81 74.6 75.7 C 74.5 70.5 70.1 66.4 64.8 66.5 Z"/><path d=" M 44.6 46.3 C 46.4 47.4 48.1 48.9 49.4 50.6 C 54.6 50 59.7 51.9 63.2 55.8 C 69 54 73.2 48.9 73.7 42.8 C 74.2 36.7 71 30.9 65.6 28.1 C 60.2 25.3 53.6 26.1 49 30.1 C 44.4 34.1 42.7 40.5 44.6 46.3 L 44.6 46.3 Z"/></g></svg>';
  if (s == 'thunder') {
    return thunder.replace(/<g>/gi, '<g fill=\'#274983\' stroke=\'#274983\'>');
  }
  if (s == 'cloudy') {
    return cloudy.replace(/<g>/gi, '<g fill=\'#274983\' stroke=\'#274983\'>');
  }
  return sunny.replace(/<g>/gi, '<g fill=\'#274983\' stroke=\'#274983\'>');

}

export const highchartFunctions = {
  drawWeatherMarker: function(chartInstance) {
    chartInstance.series.forEach(function(serie) {
      serie.points.forEach(function(point) {
        if (point.options.additional) {
          const renderer = chartInstance.renderer;
          const number = (chartInstance.series.filter(s => s.userOptions.type != 'spline').length);
          const totalW = point.pointWidth * number;
          const w = 50; // serie.columnMetrics.width;
          const margin = (totalW - w) / 2;
          const x = point.plotX + ((totalW - w) / 2);
          const src = 'data:image/svg+xml;base64,' + window.btoa(weather(point.options.additional));
          const img = renderer.image(src, x, 0, w, w);
          // img.element.setAttribute('class', 'xxxx');
          img.add();
        }
      });
    });

  },
  loadCEO: function(chart, e) {
    const x1 = 0.16;
    const x3 = 0.82;

    const y1 = 0.06;
    const y3 = 0.92;


    this.drawon(chart, x3, y3, 'The silent ambassador', 'dmr_alert');
    this.drawon(chart, x3, y1, 'The great brand ambassador', 'dmr_fight');
    this.drawon(chart, x1, y3, 'The Introverted CEO', 'dmr_accept');
    this.drawon(chart, x1, y1, 'The dominant CEO', 'dmr_solve');
  },
  drawTopicBubble: function(chart, e) {
    const x1 = 0.16;
    const x3 = 0.82;

    const y1 = 0.1;
    const y3 = 0.92;


    this.drawon(chart, x1, y1, 'fight', 'topic_bubble_fight', '#d3d3d3', '20px');
    this.drawon(chart, x3, y1, 'enjoy', 'topic_bubble_enjoy', '#d3d3d3', '20px');
    this.drawon(chart, x1, y3, 'mitigate', 'topic_bubble_mitigate', '#d3d3d3', '20px');
    this.drawon(chart, x3, y3, 'support', 'topic_bubble_support', '#d3d3d3', '20px');
    {
      const text = chart.renderer.text('distantly associated ').attr({rotation: 270}).add();
      text.attr({x: chart.plotLeft, y: chart.plotTop + (chart.plotHeight)});
    }
    {
      const text = chart.renderer.text('closely associated').attr({rotation: 270}).add();
      text.attr({x: chart.plotLeft, y: chart.plotTop + text.getBBox().height + 10});
    }
    {
      const text = chart.renderer.text('Negative').attr({rotation: 0}).add();
      text.attr({x: chart.plotLeft, y: chart.plotTop + chart.plotHeight + text.getBBox().height});
    }
    {
      const text = chart.renderer.text('Positive').attr({rotation: 0}).add();
      text.attr({
        x: chart.plotLeft + chart.plotWidth - text.getBBox().width,
        y: chart.plotTop + (chart.plotHeight) + text.getBBox().height
      });
    }
  },
  drawSentimentBubble: function(chart, e) {
    const x1 = 0.16;
    const x3 = 0.82;

    const y1 = 0.1;
    const y3 = 0.92;


    this.drawon(chart, x1, y1, 'fight', 'topic_bubble_fight', '#d3d3d3', '20px');
    this.drawon(chart, x3, y1, 'enjoy', 'topic_bubble_enjoy', '#d3d3d3', '20px');
    this.drawon(chart, x1, y3, 'mitigate', 'topic_bubble_mitigate', '#d3d3d3', '20px');
    this.drawon(chart, x3, y3, 'support', 'topic_bubble_support', '#d3d3d3', '20px');
    {
      const text = chart.renderer.text('rarely mentioned ').attr({rotation: 270}).add();
      text.attr({x: chart.plotLeft, y: chart.plotTop + (chart.plotHeight)});
    }
    {
      const text = chart.renderer.text('frequently mentioned').attr({rotation: 270}).add();
      text.attr({x: chart.plotLeft, y: chart.plotTop + text.getBBox().height + 10});
    }
    {
      const text = chart.renderer.text('Negative').attr({rotation: 0}).add();
      text.attr({x: chart.plotLeft, y: chart.plotTop + chart.plotHeight + text.getBBox().height});
    }
    {
      const text = chart.renderer.text('Positive').attr({rotation: 0}).add();
      text.attr({
        x: chart.plotLeft + chart.plotWidth - text.getBBox().width,
        y: chart.plotTop + (chart.plotHeight) + text.getBBox().height
      });
    }
  },
  drawRiskDriverGrid: function(c, e) {
    this.drawRiskDriverGridText(c, 'Damage to reputation');
  },
  drawValueDriverGrid: function(c, e) {
    this.drawValueDriverGridText(c, 'Wasted Resources', 'Target Area', 'Dormant Potential');
  },
  drawRiskDriverGridText: function(c, t1) {
    const middleX = 0.525;
    const middleY = 0.3;
    const endX = 0.975;
    const endY = 0.025;
    c.renderer.path(['M', gx(c, middleX), gy(c, middleY), 'L', gx(c, endX), gy(c, middleY), gx(c, endX), gy(c, endY), gx(c, middleX), gy(c, endY), gx(c, middleX), gy(c, middleY)])
      .attr({'stroke-width': 2, stroke: 'rgb(148,138,84)'}).add();
    this.drawonExt(c, 0.76125, 0.1625, t1, {
      color: 'rgb(148,138,84)',
      'font-size': '10px',
      'font-family': 'AdwiredBold',
      'font-weight': 'normal'
    });
  },
  drawValueDriverGridText: function(c, t1, t2, t3) {
    const xStart = 0.025;
    const yStart = 0.975;
    const xMiddle = 0.3;
    const xEnd = 0.975;
    const yMiddle = 0.3;
    const yEnd = 0.025;
    const xMiddle2 = 0.6;
    const yMiddle2 = 0.6;
    c.renderer.path(['M', gx(c, xStart), gy(c, yStart), 'L', gx(c, xMiddle), gy(c, yStart), gx(c, xEnd),
      gy(c, yMiddle), gx(c, xEnd), gy(c, yEnd), gx(c, xMiddle2), gy(c, yEnd), gx(c, xStart), gy(c, yMiddle2), gx(c, xStart), gy(c, yStart)])
      .attr({'stroke-width': 2, stroke: 'rgb(148,138,84)'}).add();
    const css = {
      color: 'rgb(148,138,84)',
      'font-size': '10px',
      'font-family': 'AdwiredBold',
      'font-weight': 'normal'
    };
    if (t1) {
      this.drawonExt(c, 0.16, 0.16, t1, css);
    }
    if (t2) {
      this.drawonExt(c, 0.5, 0.5, t2, css);
    }
    if (t3) {
      this.drawonExt(c, 0.82, 0.82, t3, css);
    }

  },
  drawon: function(chart, fx, fy, what, link, color, fontsize) {
    if (!fontsize) {
      fontsize = '10px';
    }
    const text = chart.renderer.text(what).css({
      color: color || '#000',
      'font-size': fontsize, 'font-family': 'AdwiredBold', 'font-weight': 'normal'
    }).add();
    const textBBox = text.getBBox();
    const x = chart.plotLeft + (chart.plotWidth * fx) - (textBBox.width / 2);
    const y = chart.plotTop + (chart.plotHeight * fy) - (textBBox.height / 2);
    text.attr({x: x, y: y});
    // text.on('click', function (e) {
    //     chart.highchartobject.fireEvent('labelclick', e, chart.highchartobject, link);
    // });
  },
  drawonExt: function(chart, fx, fy, what, css) {
    const text = chart.renderer.text(what).css(css).add();
    const textBBox = text.getBBox();
    const x = chart.plotLeft + (chart.plotWidth * fx) - (textBBox.width / 2);
    const y = chart.plotTop + (chart.plotHeight * fy) - (textBBox.height / 2);
    text.attr({x: x, y: y});
    return text;
  },
  risk_position_draw: function(chart) {
    const x1 = 0.16;
    const x2 = 0.5;
    const x3 = 0.82;

    const y1 = 0.1;
    const y2 = 0.45;
    const y3 = 0.78;

    this.drawon(chart, x3, y3, 'Alert', 'dmr_alert');
    this.drawon(chart, x3, y2, 'Mitigate', 'dmr_mitigate');
    this.drawon(chart, x3, y1, 'Fight', 'dmr_fight');

    this.drawon(chart, x2, y3, 'Initiate', 'dmr_initiate');
    this.drawon(chart, x2, y2, 'Evaluate', 'dmr_evaluate');
    this.drawon(chart, x2, y1, 'Coordinate', 'dmr_coordinate');

    this.drawon(chart, x1, y3, 'Accept', 'dmr_accept');
    this.drawon(chart, x1, y2, 'Communicate', 'dmr_communicate');
    this.drawon(chart, x1, y1, 'Solve', 'dmr_solve');
  },
  value_position_draw: function(chart) {
    const x1 = 0.16;
    const x2 = 0.5;
    const x3 = 0.82;

    const y1 = 0.1;
    const y2 = 0.45;
    const y3 = 0.78;

    this.drawon(chart, x3, y3, 'Activate', 'dmv_activate');
    this.drawon(chart, x3, y2, 'Differentiate', 'dmv_differentiate');
    this.drawon(chart, x3, y1, 'Lead', 'dmv_lead');

    this.drawon(chart, x2, y3, 'Repair', 'dmv_repair');
    this.drawon(chart, x2, y2, 'Optimize', 'dmv_optimize');
    this.drawon(chart, x2, y1, 'Game Change', 'dmv_game_change');

    this.drawon(chart, x1, y3, 'Rebuild', 'dmv_rebuild');
    this.drawon(chart, x1, y2, 'Assess', 'dmv_assess');
    this.drawon(chart, x1, y1, 'Experiment', 'dmv_experiment');
  }

};


@Component({
  selector: 'adw-hc-single'
  , template: '<div></div>',
  styleUrls: ['./adw-hc-single.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdwHighchartSingle implements AfterViewInit, OnInit, OnDestroy {
  // @ViewChild('chart') public chartEl: ElementRef;

  _chart: any;
  _options: any = null;

  inited: boolean = false;
  private _unsubscribeAll: Subject<any>;

  @Input()
  width: number = null;
  @Input()
  height: number = null;

  @Output()
  loadChart: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  events: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set options(o: any) {
    this._options = o;
    if (this.inited) {
      this.logger.debug('options.changed');
      this.redraw();
    }
  }

  get options(): any {
    return this._options;
  }

  constructor(public chartEl: ElementRef, private eventManager: EventManager, private logger: NGXLogger) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
  }

  onLoadChart(opt, chartInstance) {
    this.loadChart.emit({single: this, options: opt, chartInstance: chartInstance});
  }

  onEvent(opt, event) {
    this.events.emit({single: this, options: opt, event: event});
  }


  onResize() {
    if (this.inited) {
      this.logger.debug('onResize');
      this.redraw();
    }
  }

  public ngAfterViewInit() {
    this.inited = true;
    const me = this;
    this.logger.debug('ngAfterViewInit');
    this.redraw();
  }

  redraw() {
    this._redraw();
  }

  public ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this._chart) {
      try {
        this._chart.destroy();
      } catch (e) {
      }
      this._chart = null;
    }
  }


  private _redraw() {
    this.logger.debug('redraw()');
    const me = this;
    if (this._chart) {
      try {
        this._chart.destroy();
        this._chart = null;
      } catch (e) {
      }
    }
    if (this.chartEl && this.chartEl.nativeElement) {
      const ne = this.chartEl.nativeElement; // .children[0];
      ne.innerHTML = '';
      if (this._options) {
        if (!this._options) {
          this._options = {};
        }
        if (!this._options.plotOptions) {
          this._options.plotOptions = {};
        }
        if (!this._options.plotOptions.series) {
          this._options.plotOptions.series = {};
        }
        this._options.plotOptions.series.animation = false;
        // const events = {legendItemClick: function(e) {const chartInstance = e.chart;me.onEvent('legendItemClick', e);}};
        // this._options.plotOptions.series.events = events;
        if (!this._options.chart) {
          this._options.chart = {};
        }
        this._options.chart.animation = false;
        if (this.width) {
          this._options.chart.width = this.width;
        }
        if (this.height) {
          this._options.chart.height = this.height;
        }
        // this._options.chart.reflow = true;
        if (!this._options.chart.events) {
          this._options.chart.events = {};
        }
        this._options.chart.events.load = function(event) {
          const chartInstance = event.target;
          // chartInstance.reflow();

          if (chartInstance && chartInstance.series) {
            chartInstance.series.filter(c => c.options.isRegressionLine)
              .forEach(function(s) {
                s.update({
                  enableMouseTracking: false
                });
              });
          }

          me.onLoadChart(chartInstance.userOptions, chartInstance);
        };
        this._options.chart.events.selection = function(event) {
          me.onEvent('selection', event);
        };
        this._chart = new Highcharts.Chart(ne, this._options, function(chartInstance) {
        });
      }
    }

  }
}
