import {Component, Input} from '@angular/core';

export declare let Highcharts: any;

@Component({
  selector: 'adw-data-table',
  template: `
      <div *ngIf="chart && chart.type==4 && chart.data && chart.data.table" class="table-responsive">
          <table class="table">
              <thead>
              <tr>
                  <th>&nbsp;</th>
                  <th *ngFor="let c of chart.data.table.columns" [innerHtml]="convertValue(c)"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let row of chart.data.table.rows">
                  <td>{{row['entity']}}</td>
                  <td *ngFor="let v of row.values" [class]="convertStyle(v.style)">{{v.value}}</td>
              </tr>
              </tbody>
          </table>
      </div>`
})
export class DataTableContentComponent {

  @Input()
  chart: any;

  constructor() {


  }

  public convertStyle(v) {
    return '';
  }

  public convertValue(s) {
    if (s) {
      return s.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
    return '';
  }


}


