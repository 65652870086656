import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoggerConfig, NGXLogger} from './logger.service';


@NgModule({
    imports: [
        CommonModule
    ]
})
export class LoggerModule {
    static forRoot(config: LoggerConfig | null | undefined): ModuleWithProviders<LoggerModule> {
        return {
            ngModule: LoggerModule,
            providers: [
                {provide: LoggerConfig, useValue: config || {}},
                NGXLogger
            ]
        };
    }
}
