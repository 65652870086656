import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {NGXLogger} from '../utils/logging/logger.service';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {JwtHelperService} from './jwthelper.service';
import {catchError, map} from 'rxjs/operators';

export const ACCESS_DEEPDIVE: string = 'ACCESS_DEEPDIVE';

@Injectable()
export class AuthenticationService {

  // private tempToken: string;

  constructor(private logger: NGXLogger, private http: HttpClient, private jwthelper: JwtHelperService, private router: Router) {
    // set token if saved in local storage
  }

  setTempToken(s: string) {
    // this.tempToken = s;
    localStorage.setItem('token', s);
  }

  set token(s: string) {
    localStorage.setItem('token', s);
  }


  get token() {
    // if (this.tempToken) {
    //   return this.tempToken;
    // }
    return this.jwthelper.tokenGetter();
  }

  public tokenNotExpired(): boolean {
    return !this.jwthelper.isTokenExpired(this.token);
  }


  login(username: string, password: string): Observable<any> {
    this.logger.debug('login(' + username + ',***)');
    const me = this;
    return this.http
      .post('/api/auth/login.json', {username: username, password: password})
      .pipe(
        map((response: any) => {
          // login successful if there's a jwt token in the response
          const token = response && response.token;
          if (token) {
            return me.tokenLogin(token)
          } else {
            this.logger.debug('login failed: ' + username);
            // return false to indicate failed login
            return {success: false, message: response.message || 'Login failed'};
          }
        }),
        catchError((err: HttpErrorResponse, c) => {
          this.logger.debug('login failed: ' + username, err);
          const details = err.error;
          details.success = false;
          return observableThrowError(details);
        })
      );
  }

  tokenLogin(token) {
    const any = this.jwthelper.decodeToken(token);
    const username = any.sub;
    if (this.needChange(any.scopes)) {
      this.logger.debug('login success: ' + username + ', MUST CHANGE PW');
      localStorage.setItem('token', token);
      return {success: true, changePassword: true};
    } else {
      localStorage.setItem('token', token);
      let defaultPage = '/analytics/';
      if (this.can('ACCESS_ANALYTICS')) {
        defaultPage = '/analytics/';
      } else if (this.can('ACCESS_MEDIATICKER')) {
        defaultPage = '/clipping/';
      } else if (this.can('ACCESS_API')) {
        defaultPage = '/customer-api/';
      } else {
        defaultPage = '/pages/login/';
      }
      this.logger.debug('login success: ' + username + ', token: ' + token);
      return {success: true, defaultPage: defaultPage};
    }
  }


  isLoggedIn() {
    return this.tokenNotExpired();
  }

  getRoles() {
    try {
      const any = this.jwthelper.decodeToken(this.token);
      return any.scopes;
    } catch (e) {
    }
    return [];
  }

  get username() {
    try {
      const any = this.jwthelper.decodeToken(this.token);
      return any.sub;
    } catch (e) {
    }
    return null;
  }


  logout()
    :
    void {
    // clear token remove user from local storage to log user out
    // console.log('Logout');
    // this.tempToken = null;
    this.logger.debug('Loggging out');
    localStorage.removeItem('token');
    this.router.navigate(['/pages/login']);
  }

  removeToken()
    :
    void {
    this.logger.debug('Removing Token');
    // this.tempToken = null;
    localStorage.removeItem('token');
  }

  needChangePassword() {
    return this.needChange(this.getRoles());
  }

  needChange(any) {
    if (any && any.length > 0) {
      return any.indexOf('MUST_CHANGE_PASSWORD') >= 0;
    }
    return false;
  }

  can(ro
        :
        any
  ) {
    return this.getRoles().find(s => s == ro);
  }
}
