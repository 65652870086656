<div class="modal fade in show" *ngIf="message!=null" style="display:block;" tabindex="-1" role="dialog" aria-labelledby="Comparison" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header primary-bg">
        <h4 class="modal-title bold-font pull-left">Download</h4>
        <button type="button" class="modal-close-button pull-right" aria-label="Close" (click)="close()">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div [innerHTML]="message">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade in" *ngIf="message!=null" [class.show]="message!=null"></div>
