import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DownloadService} from './downloadservice';
import {DownloadComponent} from './download';
import {LoggerModule} from '../logging/index';


@NgModule({
    imports: [
        CommonModule, TranslateModule, LoggerModule
    ],
    declarations: [
        DownloadComponent
    ],
    exports: [
        DownloadComponent
    ]
})
export class DownloadModule {

  static forRoot(): ModuleWithProviders<DownloadModule> {
    return {ngModule: DownloadModule, providers: [DownloadService]};
  }

}
