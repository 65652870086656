import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {DynCloudComponent} from './dyncloud.component';
import {DynMatrixComponent} from './dynmatrix.component';
import {NouiFormatter, NouisliderComponent} from 'ng2-nouislider';
import {NGXLogger} from '../logging/logger.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {ScriptService} from '@adw/services/script.service';

@Component({
  selector: 'dyn-combi-dialog',
  template: '<adw-zoom (close)="dialog.close()" [data]="zoomOptions" class="chartZoom">' +
    '<dyn-combi [endless]="5000" style="flex: 1 1 100%; width: 100%;" [data]="zoomOptions"></dyn-combi>' +
    '</adw-zoom>'
})
export class DynCombiDialog {
  zoomOptions: any = null;

  constructor(public dialog: MatDialogRef<DynCombiDialog>) {
  }
}


export class TimeFormatter implements NouiFormatter {
  constructor(private d: DynComboComponent) {
  }

  to(value: number): string {
    try {
      const df = d3.timeFormat('%d.%m.%Y');
      const me = this;
      if (this.d && this.d.cloud && this.d.cloud.weekInterpolation) {
        return (df(this.d.cloud.weekInterpolation(value / 10000)));
      }
    } catch (e) {
    }
    return '';
  };

  from(value: string): number {
    return 0;
  }
}

@Component({
  selector: 'dyn-combi',
  templateUrl: './dyn-combi.component.html',
  encapsulation: ViewEncapsulation.None,
  host: {'style': 'position:relative'}
})
export class DynComboComponent implements AfterViewInit, OnInit, OnDestroy {
  _data: any;
  matrixTs: any;
  private _cloud: DynCloudComponent;
  private _matrix: DynMatrixComponent;

  @Input()
  autostart: boolean = false;

  @Input()
  endless: number = 0;

  @Input()
  identifier: any;

  words: any;
  date: string;
  state: number = 0;
  tempo: number = 30000;
  trace: number = 1;
  initted: boolean = false;
  running: boolean = false;
  step: number = 1;

  sliderConfig: any = {
    step: 1, connect: true, range: {min: 0, max: 10000}
    , tooltips: new TimeFormatter(this)
  };

  @ViewChild(DynCloudComponent)
  set cloud(v: DynCloudComponent) {
    setTimeout(() => {
      this._cloud = v;
      this.update();
    }, 0);
  }

  get cloud() {
    return this._cloud;
  }


  @ViewChild(NouisliderComponent)
  slider: NouisliderComponent;

  @ViewChild(DynMatrixComponent)
  set matrix(matrix: DynMatrixComponent) {
    setTimeout(() => {
      this._matrix = matrix;
      this.update();
    }, 0);
  }

  get matrix(): DynMatrixComponent {
    return this._matrix;
  }

  public scriptsLoaded: boolean = false;

  constructor(private dialog: MatDialog,
              private scriptService: ScriptService,
              private elementRef: ElementRef, private change: ChangeDetectorRef, private logger: NGXLogger) {

    this.scriptService.load('d3',
      '//cdnjs.cloudflare.com/ajax/libs/d3-cloud/1.2.5/d3.layout.cloud.min.js',
      '//cdnjs.cloudflare.com/ajax/libs/d3/4.13.0/d3.min.js').then(value => {
      this.scriptsLoaded = true;
      this.update();
    });

  }

  @Input()
  set data(d: any) {
    this.logger.debug({text: 'DynCombi: set Data', d});
    this._data = d;
    this.update();
  }

  update() {
    if (this.scriptsLoaded && this._data && this.cloud && this.matrix) {
      this.stop();
      this.tempo = this._data.matrix[0].data.length * 500;
      // Prev: this.sliderConfig = Object.assign({},this.sliderConfig,{step: (10000.0 / this._data.matrix[0].data.length) });
      this.step = 1; // Was (10000.0 / this._data.matrix[0].data.length);
      this.matrixTs = [...this._data.matrix];
      this.words = [...this._data.words];
      this.matrix.bands = [...this._data.bands];
      this.matrix.data = [...this._data.matrix];
      this.cloud.data = [...this._data.words];
      this.draw();
    } else {
      console.log('update', this.scriptsLoaded, this._data, this.cloud, this.matrix);
    }

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // const me = this;
    // if (this._data) {
    //   me.draw();
    //   this.initted = true;
    // }
  }

  draw(forceStart: boolean = false) {
    try {
      const me = this;
      me.matrix.redraw();
      me.cloud.draw();
      if (this._data) {
        this.stop();
        if (this.autostart || forceStart) {
          console.log('starting.....');
          setTimeout(() => me.running = true);
          d3.select(this.elementRef.nativeElement)
            .transition()
            .ease(d3.easeLinear)
            .duration(this.tempo)
            .tween('week', () => {
              return function(t) {
                me.redraw(t);
              };
            })
            .on('end', () => {
              me.running = false;
              if (me.endless) {
                setTimeout(() => {
                  me.start();
                }, me.endless);
              }
              // me.matrix.clearTraces();
              // this.matrix.enableInteraction(box)
            });
        } else {
          console.log('not starting', forceStart, this.autostart);
        }
      }
    } catch (e) {
      console.log('error', e);
    }

  }

  redraw(t) {
    const df = d3.timeFormat('%d.%m.%Y');
    const me = this;
    this.state = t * 10000;
    const date = me.cloud.weekInterpolation(t);
    me.date = (df(date));
    me.cloud.drawCloud(t);
    me.matrix.drawDate(t, date);

    // me.change.markForCheck();
  }

  onChangeSlider(v: any) {
    this.redraw(v / 10000);
  }

  start() {
    this.draw(true);
  }

  stop() {
    try {
      console.log('stopping');
      this.running = false;
      d3.select(this.elementRef.nativeElement).transition().duration(0);
    } catch (e) {
    }
  }

  toggle() {
    try {
      if (this.running) {
        this.stop();
      } else {
        this.start();
      }
    } catch (e) {
    }
  }

  zoom() {
    if (this.running) {
      this.stop();
    }
    const dialogRef = this.dialog.open(DynCombiDialog, {panelClass: 'nopadding-dialog'});
    dialogRef.componentInstance.zoomOptions = Object.assign({}, this._data);
  }

  ngOnDestroy(): void {
    this.stop();
  }
}
