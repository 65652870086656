import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NGXLogger} from '../utils/logging/logger.service';
import {HttpClient} from '@angular/common/http';
import {ApplicationSetup} from '../app.model';

@Injectable()
export class ConfigurationService {
  private configuration$: BehaviorSubject<ApplicationSetup> = new BehaviorSubject<ApplicationSetup>(null);
  public configuration: Observable<ApplicationSetup> = this.configuration$.asObservable();

  constructor(private logger: NGXLogger, private http: HttpClient) {
  }

  getConfiguration(): ApplicationSetup {
    return this.configuration$.getValue();
  }

  has(what: string): boolean {
    let conf = this.getConfiguration();
    if (conf) {
      let features = conf.features;
      if (features) {
        return !!features[what];
      }
    }
    return false;
  }


  loadConfiguration() {
    return new Promise<ApplicationSetup>((resolve, reject) => {
      if (!this.configuration$.getValue()) {
        this.http.get<ApplicationSetup>('/api/application.json')
          .pipe(catchError((e) => of({} as ApplicationSetup)))
          .subscribe(
            (r: ApplicationSetup) => {
              this.configuration$.next(r);
              resolve(r);
            },
            (e) => {
              this.configuration$.next({} as ApplicationSetup);
              resolve({} as ApplicationSetup);
            }
          );
      } else {
        resolve(this.configuration$.getValue());
      }
    });
  }
}
