import {DynData} from "./dyn_data";
import {NGXLogger} from "../logging/logger.service";
declare let d3: any;


export class MatrixTimeseriesData {

    startdate: any;
    enddate: any;
    minX:any;
    maxX:any;
    minY:any;
    maxY:any;
    minR:any;
    maxR:any;
    xAxisName:string="Perception";
    yAxisName:string="Presence";


    constructor(private logger: NGXLogger, public data: any[]) {
        this.startdate = d3.min(this.data, (brand) => d3.min(brand.data, (data) => DynData.str2Date(data.date)));
        this.enddate = d3.max(this.data, (brand)=>d3.max(brand.data,(data)=>DynData.str2Date(data.date)));
        this.minX = d3.min(this.data, (brand) => d3.min(brand.data, (data) => data.x));
        this.maxX = d3.max(this.data, (brand) => d3.max(brand.data, (data) => data.x));
        this.minY = d3.min(this.data, (brand) => d3.min(brand.data, (data) => data.y));
        this.maxY = d3.max(this.data, (brand) => d3.max(brand.data, (data) => data.y));
        this.minR = d3.min(this.data, (brand) => d3.min(brand.data, (data) => data.r || 30));
        this.maxR = d3.max(this.data, (brand) => d3.max(brand.data, (data) => data.r || 30));
        this.logger.debug({startdate: DynData.date2Str(this.startdate), enddate: DynData.date2Str(this.enddate), minX:this.minX, maxX:this.maxX, minY:this.minY, maxY:this.maxY})
    }

    interpolateData(week) {
        let me = this;
        if (this.data) {
            return me.data.map(function (d) {
                if (!d) {
                    let p = [];
                    let localWeek = week - (1000 * 24 * 3600 * 10);
                    let opaIdx = 0;
                    let delta = (1000 * 24 * 3600) / 10;
                    for (let i = localWeek; i < week; i = i + delta) {
                        p.push({
                            x: DynData.interpolateValues(d.data, 'x', i),
                            y: DynData.interpolateValues(d.data, 'y', i),
                            type: 'trace',
                            colorType: d.colorType,
                            opa: opaIdx
                        });
                        opaIdx += 0.0005;
                    }
                    p.push({
                        x: DynData.interpolateValues(d.data, 'x', week),
                        y: DynData.interpolateValues(d.data, 'y', week),
                        colorType: d.colorType,
                        type: "data",
                        opa: 1
                    });
                    return {
                        name: d.name,
                        points: p
                    };
                } else {
                    let p = [];
                    let delta = (1000 * 24 * 3600) / 10;
                    let interpolate = DynData.interpolate(d.data, week, 10, delta);
                    interpolate.forEach((value, index, array)=>{
                        let opa = (index+1)*0.05;
                        let type="trans";
                        if (index==9) {
                            opa=1;
                            type="data";
                        }
                        p.push({
                            x: value.x,
                            y: value.y,
                            colorType: d.colorType,
                            type: type,
                            opa: opa
                        });
                    });
                    return {
                        name: d.name,
                        points: p
                    };

                }
            });
        }
        return {};

    }



}
