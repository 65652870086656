import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './security/auth.guard';
import {AppResolver} from './app.resolver';
import {FrontpageComponent} from './frontpage.component';
import {ExtClippingsModule} from './ext-clippings/ext-clippings.module';

// Layouts

export const routes: Routes = [
  {
    path: '',
    resolve: [AppResolver],
    component: FrontpageComponent,
    pathMatch: 'full',
  },
  {
    path: 'analytics',
    loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
    canActivate: [AuthGuard],
    data: {roles: ['ACCESS_ANALYTICS']}
  },
  {
    path: 'archive',
    loadChildren: () => import('./archive/clipping-archive.module').then(m => m.ClippingArchiveModule),
    canActivate: [AuthGuard],
    data: {roles: ['ACCESS_ANALYTICS']}
  },
  {
    path: 'customer-api',
    loadChildren: () => import('./customer-api/customer-api.module').then(m => m.CustomerAPIModule),
    canActivate: [AuthGuard], data: {roles: ['ACCESS_API']}
  },
  {
    path: 'export',
    loadChildren: () => import('./exporter/exporter.module').then(m => m.ExporterModule),
    canActivate: [AuthGuard],
    data: {roles: ['ACCESS_ANALYTICS']}
  },
  {
    path: 'clipping',
    loadChildren: () => import('./clipping-library/clipping-library.module').then(m => m.ClippingLibraryModule),
    canActivate: [AuthGuard],
    data: {roles: ['ACCESS_MEDIATICKER']}
  },
  {
    path: 'deepdive',
    loadChildren: () => import('./deepdive2/deepdive.module').then(m => m.DeepdiveModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ext-clippings',
    loadChildren: () => import('./ext-clippings/ext-clippings.module').then(m => m.ExtClippingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/ui/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: {roles: ['ACCESS_ADMIN']}
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
