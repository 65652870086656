import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {ZoomComponent} from "./zoom";


@NgModule({
    imports: [
        CommonModule, TranslateModule
    ],
    declarations: [
        ZoomComponent
    ],
    exports: [
        ZoomComponent
    ]
})
export class ZoomModule {


}