import {AfterViewInit, Component, ElementRef, Input, OnDestroy} from '@angular/core';
import {DynData} from './dyn_data';


@Component({
  selector: 'dyn-cloud',
  template: ''
})
export class DynCloudComponent implements AfterViewInit, OnDestroy {
  _data: any;
  sizeScale: any;
  opaScale: any;
  cl: any;
  layout: any;
  weekInterpolation: any;


  opaCol = 3;
  padding: number = 5;

  get data() {
    return this._data;
  }

  @Input()
  set data(d: any) {
    this._data = d;
  }

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
  }


  get enddate() {
    return DynData.max(this._data, (d) => d.data, (b) => DynData.str2Date(b[0]));
  }

  get startdate() {
    return DynData.min(this._data, (d) => d.data, (b) => DynData.str2Date(b[0]));
  }

  drawCloud(t) {
    let me = this;
    if (!this._data) {
      return false;
    }
    let interPolWeek = this.weekInterpolation(t);
    this.cl
      .selectAll('text')
      .data(me._data)
      .style('fill-opacity', function(d) {
        let val = DynData.interpolateValues2(d.data, interPolWeek, me.opaCol);
        let si = me.opaScale(val);
        return si;
      });
  }


  draw() {
    let me = this;

    this.elementRef.nativeElement.innerHTML = '';
    if (this._data) {

      let width = this.elementRef.nativeElement.getBoundingClientRect().width;
      let height = this.elementRef.nativeElement.getBoundingClientRect().height;
      let minX = d3.min(this._data, function(b) {
        return d3.min(b.data, function(k) {
          return k[me.opaCol];
        });
      });
      let maxX = d3.max(this._data, function(b) {
        return d3.max(b.data, function(k) {
          return k[me.opaCol];
        });
      });
      let minS = d3.min(this._data, (b) => b.size);
      let maxS = d3.max(this._data, (b) => b.size);

      me.sizeScale = d3.scaleLinear().domain([minS, maxS]).range([10, 50]);
      me.opaScale = d3.scaleQuantile().domain([0, maxX * 0.3, maxX * 0.7, maxX]).range([0.05, 0.1, 0.7, 1]);

      me.weekInterpolation = d3.interpolateDate(this.startdate, this.enddate);

      this.layout = d3.layout.cloud()
        .size([width - me.padding, height - me.padding])
        .font((d) => 'Adwired')
        .words(me._data)
        .padding(5)
        //.rotate(function() { return ~~(Math.random() * 2) * 90; })
        .fontSize(function(d) {
          return me.sizeScale(d.size);
        })
        .rotate(0)
        .on('end', (t) => me.initCloud(t));
      this.layout.start();
    }
  }

  initCloud(words) {
    let me = this;
    let width = this.elementRef.nativeElement.getBoundingClientRect().width;
    let height = 400;


    this.cl = d3.select(this.elementRef.nativeElement)
      .append('svg')
      .attr('width', this.layout.size()[0])
      .attr('height', this.layout.size()[1])
      .attr('class', 'wordcloud')
      .append('g')
      .attr('transform', 'translate(' + this.layout.size()[0] / 2 + ',' + this.layout.size()[1] / 2 + ')');
    this.cl
      .selectAll('text')
      .data(words)
      .enter().append('text')
      .style('font-size', function(d) {
        return d.size + 'px';
      })
      .style('font-family', 'Adwired')
      .style('fill', function(d, i) {
        return d.colorType;
      })
      .attr('text-anchor', 'middle')
      .attr('transform', function(d) {
        return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
      })
      .text(function(d) {
        return d.text;
      });

  }

  ngOnDestroy(): void {
  }


}
