import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from './configuration/configuration.service';
import {Router} from '@angular/router';
import {ApplicationSetup} from './app.model';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  host: {'[class]': 'theme'}
})
export class AppComponent implements OnInit {
  valid: boolean = false;
  theme: string;
  applicationSetup: ApplicationSetup;

  ngOnInit(): void {
    this.configuration.loadConfiguration();
    this.configuration.configuration.subscribe((r) => {
      this.applicationSetup = r;
      if (r) {
        if (r.project) {
          this.valid = true;
          if (r.theme) {
            this.theme = r.theme;
          } else {
            this.theme = r.project;
          }
        } else {
          this.valid = true;
          this.router.navigate(['/pages/offline']);
        }
      }
    });
  }


  constructor(private configuration: ConfigurationService,
              private router: Router,
              private http: HttpClient,
              translate: TranslateService) {
    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('en');
    translate.currentLang = 'en';
  }

  doDataAction(action: any) {
  }
}
